<template>
  <div class="container">
    <Header :active="0" />
    <div class="carousel-box">
      <!-- height="4.18229166rem" -->
      <el-carousel
        :height="carouselHeight"
        indicator-position="outside"
        trigger="click"
        style="width: 100%"
      >
        <el-carousel-item>
          <div class="carousel-item">
            <img
              class="carousel-img"
              ref="carouselImg"
              style="width: 100%"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel1.png"
              alt=""
            />
            <div class="carousel-item-text-box">
              <div class="carousel-item-text-box-title">
                <span>时刻</span>拥有亲人陪伴
              </div>
              <div class="carousel-item-text-box-tips">
                Relatives always accompany
              </div>
              <div class="horizontal-line"></div>
              <div class="carousel-item-text-box-info">
                1CARE利用高端传感器以及深度学习算法，感知老年人日常生活的细微变化，这些变化可能表明身体健康存在严重的问题，1CARE可以在身体健康发生严重问题之前发出预警。
              </div>
              <img
                class="carousel-item-text-box-btn"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel-btn.png"
                @click="handleToAboutUs"
              />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel-item">
            <img
              class="carousel-img"
              ref="carouselImg"
              style="width: 100%"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel2.png"
              alt=""
            />
            <div class="carousel-item-text-box">
              <div class="carousel-item-text-box-title">
                赋予护理更高的智慧化水平
              </div>
              <div class="carousel-item-text-box-tips">
                Give nursing higher intelligent level
              </div>
              <div class="horizontal-line"></div>
              <div class="carousel-item-text-box-info">
                主动为护理和管理人员提供可操作的专业见解，让老人及其亲人安心
              </div>
              <img
                class="carousel-item-text-box-btn"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel-btn.png"
                @click="handleToAboutUs"
              />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel-item">
            <img
              class="carousel-img"
              ref="carouselImg"
              style="width: 100%"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel3.png"
              alt=""
            />
            <div class="carousel-item-text-box">
              <div class="carousel-item-text-box-title">
                健康监测技术的创新者
              </div>
              <div class="carousel-item-text-box-tips">
                Health monitoring technology innovators
              </div>
              <div class="horizontal-line"></div>
              <div class="carousel-item-text-box-info">
                1CARE为居家/社区养老的老年人、家属，以及服务机构和护理人员，提供可操作性的专业建议。
              </div>
              <img
                class="carousel-item-text-box-btn"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/carousel-btn.png"
                @click="handleToAboutUs"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="section1">
      <div class="section1-row">
        <div class="section1-row-box section1-row-box1">
          <div class="section1-row-box1-title">多模融合、智能感知</div>
          <div
            :style="{ marginTop: pxToRem(10) }"
            class="section1-row-box1-tag"
          >
            1CARE凭借首创的生活活动感知，融合医疗信息的慢病早期预警研究，使其在智慧健康领域极具应用前景和竞争价值；
          </div>
          <!-- <div class="section1-row-box1-tag">
            1CARE健康为您免除首年度系统使用费。
          </div> -->
          <div
            :style="{ marginTop: pxToRem(25) }"
            class="horizontal-line"
          ></div>
          <div class="section1-row-box1-text">
            研究证明，使用1CARE健康，可减少60%跌倒和增加60%的床位入住率，以及可以早期识别尿道感染或抑郁症等疾病；
          </div>
          <img class="wave" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/wave.png" alt="" />
        </div>
        <div class="section1-row-box section1-row-box2">
          <div class="section1-row-img-box section1-row-img-box1">
            <div class="section1-row-img-box-title-box">
              <div class="section1-row-img-box-title">
                <span>信标</span>
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png" alt="" />
              </div>
              <div class="section1-row-img-box-text">精准定位/信</div>
              <div class="section1-row-img-box-text">息传输…</div>
            </div>
          </div>
        </div>
      </div>
      <div :style="{ marginTop: pxToRem(30) }" class="section1-row">
        <div class="section1-row-box section1-row-box3">
          <div class="section1-row-img-box section1-row-img-box2">
            <div
              :style="{ paddingTop: pxToRem(105), marginLeft: pxToRem(69) }"
              class="section1-row-img-box-title-box"
            >
              <div class="section1-row-img-box-title">
                <span>智能手表</span>
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png" alt="" />
              </div>
              <div class="section1-row-img-box-text">双向通话/心电监护</div>
              <div class="section1-row-img-box-text">持续监测/一键呼救/…</div>
            </div>
          </div>
        </div>
        <div
          :style="{ marginTop: pxToRem(-5) }"
          class="section1-row-box section1-row-box4"
        >
          <div class="section1-row-img-box section1-row-img-box3">
            <div
              :style="{ paddingTop: pxToRem(114), marginLeft: pxToRem(68) }"
              class="section1-row-img-box-title-box"
            >
              <div class="section1-row-img-box-title">
                <span>智能手环</span>
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png" alt="" />
              </div>
              <div class="section1-row-img-box-text">体征持续监测/精准</div>
              <div class="section1-row-img-box-text">定位/一键呼救/…</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="section2-header-box">
        <div class="section2-header-box-title">
          无论我们身处何处，都不会错过任何事情，
        </div>
        <div class="section2-header-box-title">知道他们……</div>
        <div class="section2-header-box-tips">
          No matter where, wouldn't miss anything
        </div>
      </div>
      <div class="section2-box">
        <div class="section2-box-row">
          <template v-if="!sectionTwoObj">
            <div
              v-for="(item, index) in sectionTwoList"
              :key="item.img"
              class="section2-box-row-item"
            >
              <div @click="handleShowDetail(index)">
                <div class="section2-box-row-item-img">
                  <img
                    :src="item.img"
                    :style="{
                      width: pxToRem(item.width),
                      height: pxToRem(item.height),
                    }"
                    alt=""
                  />
                </div>
                <div class="section2-box-row-item-text1">{{ item.text1 }}</div>
                <div class="section2-box-row-item-text2">{{ item.text2 }}</div>
              </div>
            </div>
          </template>
          <div class="sectionItemBig" @click="handleHideDetail" v-else>
            <div class="itemLeft">
              <img :src="sectionTwoObj.img" alt="" />
              <p>{{ sectionTwoObj.text1 }}</p>
              <p>{{ sectionTwoObj.text2 }}</p>
            </div>
            <div class="itemRight">
              <p v-for="(el, ind) in sectionTwoObj.textArr" :key="ind">
                {{ el }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section2-info-box">
        <div>
          <img
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
            :style="{
              width: pxToRem(20),
              height: pxToRem(20),
              marginTop: pxToRem(8),
            }"
            alt=""
          />
        </div>
        <div class="section2-info-box-text">
          1CARE健康采用多模融合智能传感器，通过日常生活活动感知和深度学习算法，感知身体健康的细微变化，在身体健康发生恶化之前发出预警；因此，1CARE可以让我们安心，无论身在何处，无论他们在做什么，我们的亲人很安全。
        </div>
      </div>
    </div>
    <div class="section3-bg">
      <div class="section3">
        <div class="section2-header-box">
          <div class="section2-header-box-title">主动健康，早期预警</div>
          <div class="section2-header-box-tips">
            Active health, early warning
          </div>
        </div>
        <div :style="{ marginTop: pxToRem(20) }" class="section2-info-box">
          <div>
            <img
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
              :style="{
                width: pxToRem(20),
                height: pxToRem(20),
                marginTop: pxToRem(8),
              }"
              alt=""
            />
          </div>
          <div class="section2-info-box-text">
            1CARE使用人工智能、机器学习和先进算法来分析生命体征和用户行为，以检测日常活动（例如饮食、步行或烹饪）和其他日常习惯的细微变化，比如去洗手间；当发现相比正常行为出现偏差时（例如，如果他们不吃饭或比平时少活动，或者他们的睡眠不安宁），系统会向家人或看护人发送警报。这些宝贵的信息允许早期干预以预防各种风险，包括跌倒、营养不良、抑郁或尿路感染；
          </div>
        </div>
        <div class="section3-box">
          <div class="section3-box-left">
            <el-carousel
              height="4.73958333rem"
              trigger="click"
              indicator-position="outside"
            >
              <el-carousel-item
                v-for="item in section3Carousel"
                :key="item.img"
              >
                <div class="section3-box-left-carousel">
                  <img
                    :style="{
                      width: pxToRem(item.width),
                      height: pxToRem(item.height),
                    }"
                    :src="item.img"
                    alt=""
                  />
                  <!-- <el-image
                    style="width: 100%; height: 100%"
                    :src="require('@/assets/home/section3-carousel-img1.png')"
                    lazy
                  ></el-image> -->
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="section3-box-right">
            <div class="section3-box-right-top">
              <div class="section3-box-right-top-left">
                <div class="section3-box-right-top-title">
                  <span>早期预警</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(10) }"
                  class="horizontal-line"
                ></div>
                <div class="section3-box-right-top-text">
                  长时间待在卧室可能预示着抑郁的发生;夜间尿急、尿频可能预示着尿道疾病的发生;
                </div>
                <div class="section3-box-right-top-title">
                  <span>跌倒预警</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(10) }"
                  class="horizontal-line"
                ></div>
                <div class="section3-box-right-top-text">
                  深度学习模型可以不断进行自我学习和改进，提前预测跌倒风险;
                </div>
                <div class="section3-box-right-top-title">
                  <span>精确定位</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(10) }"
                  class="horizontal-line"
                ></div>
                <div class="section3-box-right-top-text">
                  定位信标可洞悉他们将时间花在哪里——比如他们是否去过厨房做饭，或者在浴室的时间比平时多;
                </div>
              </div>
              <div class="section3-box-right-top-right">
                <div class="section3-box-right-top-title">
                  <span>实时预警</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(10) }"
                  class="horizontal-line"
                ></div>
                <div
                  :style="{ marginTop: pxToRem(20) }"
                  class="section3-box-right-top-text"
                >
                  蝕摸式报警按扭，长按报警，亲属或护理人员可就近处警;
                </div>
                <div
                  :style="{ marginTop: pxToRem(30) }"
                  class="section3-box-right-top-title"
                >
                  <span>慢病预测</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(7) }"
                  class="horizontal-line"
                ></div>
                <div
                  :style="{ marginTop: pxToRem(20) }"
                  class="section3-box-right-top-text"
                >
                  利用AI深度学习，提前预警血糖、血压、心率波动情况，防止意外发生;
                </div>
                <div
                  :style="{ marginTop: pxToRem(30) }"
                  class="section3-box-right-top-title"
                >
                  <span>一流技术</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(7) }"
                  class="horizontal-line"
                ></div>
                <div
                  :style="{ marginTop: pxToRem(20) }"
                  class="section3-box-right-top-text"
                >
                  高端智能传感器，可识别、学习、洞察日常活动行为及体征数据；
                </div>
                <div
                  :style="{ marginTop: pxToRem(30) }"
                  class="section3-box-right-top-title"
                >
                  <span>触摸通话</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(7) }"
                  class="horizontal-line"
                ></div>
                <div
                  :style="{ marginTop: pxToRem(20) }"
                  class="section3-box-right-top-text"
                >
                  内置通话按钮，只需轻按一下即可与您通话;
                </div>
              </div>
            </div>
            <div class="section3-box-right-bottom">
              <div class="section3-box-right-bottom-left">
                <div
                  :style="{ marginTop: pxToRem(0), width: pxToRem(255) }"
                  class="section3-box-right-top-title"
                >
                  <span>超长在线</span>
                  <img
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                    :style="{ width: pxToRem(23), height: pxToRem(23) }"
                    alt=""
                  />
                </div>
                <div
                  :style="{ marginTop: pxToRem(7) }"
                  class="horizontal-line"
                ></div>
                <div
                  :style="{ marginTop: pxToRem(30) }"
                  class="section3-box-right-top-text"
                >
                  超长待机30天 工作时间24*14天（理想状态）
                </div>
              </div>
              <div class="section3-box-right-bottom-right">
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-power.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section4">
      <div class="section4-header-box">
        <div class="section4-header-box-title">
          <span>智能感知</span>
          <span class="section4-header-box-title-circle"></span>
          <span>早期预警</span>
        </div>
        <div class="section4-header-box-tips">
          Intelligent perception, early warning
        </div>
      </div>
      <div class="section4-box">
        <el-row>
          <el-col :span="12" style="padding: 0 0.10416666rem"
            ><div class="section4-box-item">
              <div class="section4-box-item-block"></div>
              <!-- <img
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img1.png"
                alt=""
              /> -->
              <el-image
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img1.png"
                lazy
              ></el-image>
              <div class="section4-box-item-text-box">
                <div class="section4-box-item-text-box-title">
                  <span>主动预警</span>
                  <div class="section4-box-item-text-box-title-img"></div>
                </div>
                <div
                  :style="{ marginTop: pxToRem(5) }"
                  class="section4-box-item-text-box-tips"
                >
                  中国65岁以上人群抑郁症发病率达15%，目前有超过9500万抑郁症患者；
                </div>
                <div class="section4-box-item-text-box-info">
                  1CARE能够感知日常生活活动的细微变化，并识别潜在的健康恶化问题；例如，长时间停留在卧室可能预示着抑郁；根据睡眠质量等问题，还会提醒家属或护理人员佩戴者可能出现的跌倒风验；
                </div>
              </div>
            </div></el-col
          >
          <el-col :span="12" style="padding: 0 0.10416666rem">
            <div class="section4-box-item">
              <div class="section4-box-item-block"></div>
              <!-- <img
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img2.png"
                alt=""
              /> -->
              <el-image
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img2.png"
                lazy
              ></el-image>
              <div class="section4-box-item-text-box">
                <div class="section4-box-item-text-box-title">
                  <span>位置感知</span>
                  <div class="section4-box-item-text-box-title-img"></div>
                </div>
                <div class="section4-box-item-text-box-tips">
                  居家老人营养不良率高达 40-60%；
                </div>
                <div class="section4-box-item-text-box-info">
                  通过精准定位分析，可以知道她们在哪里度过了美好的时光，这揭示了她们的思想；例如，花更少的时间在厨房间可能表明自我忽视或营养不良，相反则可能表示近期心情愉悦、营养充足；
                </div>
              </div>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12" style="padding: 0 0.10416666rem"
            ><div class="section4-box-item">
              <div class="section4-box-item-block"></div>
              <!-- <img
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img3.png"
                alt=""
              /> -->
              <el-image
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img3.png"
                lazy
              ></el-image>
              <div class="section4-box-item-text-box">
                <div class="section4-box-item-text-box-title">
                  <span>实时关注</span>
                  <div class="section4-box-item-text-box-title-img"></div>
                </div>
                <div class="section4-box-item-text-box-tips">
                  每19分钟就有一名老年人因跌倒而死亡；
                </div>
                <div class="section4-box-item-text-box-info">
                  据世界卫生组织数据，全球每年有30余万人死于跌倒，其中一半是60岁以上老年人。在我国，跌倒已成为65岁以上老年人因伤致死的首位原因，且年龄越大，因跌倒而伤亡的风险越高；1CARE通过日常行为的识别和AI趋势分析，可早期识别摔倒风险，实现早期预警；
                </div>
              </div>
            </div></el-col
          >
          <el-col :span="12" style="padding: 0 0.10416666rem"
            ><div class="section4-box-item">
              <div class="section4-box-item-block"></div>
              <!-- <img
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img4.png"
                alt=""
              /> -->
              <el-image
                class="section4-box-item-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img4.png"
                lazy
              ></el-image>
              <div class="section4-box-item-text-box">
                <div class="section4-box-item-text-box-title">
                  <span>亲情圈</span>
                  <div class="section4-box-item-text-box-title-img"></div>
                </div>
                <div class="section4-box-item-text-box-tips">
                  可以在亲情圈和家人分享自己生活状态；
                </div>
                <div class="section4-box-item-text-box-info">
                  通过1CARE程序及时发出警报，让家人随时了解他们所爱的人是否健康；当需要帮助时，1CARE可以轻松沟通；亲情圈让父母感受到，他们并没有被抛弃；
                </div>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <div class="section5-bg">
      <div class="section5">
        <div class="section5-header-box">
          <div class="section5-header-box-title">生活风险感知</div>
          <div class="section5-header-box-tips">Life risk perception</div>
        </div>
        <div class="section5-box">
          <el-row>
            <el-col :span="6" style="padding: 0 0.078125rem"
              ><div
                :style="{ marginTop: pxToRem(100) }"
                class="section5-box-item"
              >
                <div class="section5-box-item-img">
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section5-img1.png" alt="" />
                </div>
                <div class="section5-box-item-title">跌倒预警</div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>睡眠减少</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>不吃饭</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>活动强度减少</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>高血压</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>心率异常</span>
                </div>
              </div></el-col
            >
            <el-col :span="6" style="padding: 0 0.078125rem"
              ><div class="section5-box-item">
                <div class="section5-box-item-img">
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section5-img2.png" alt="" />
                </div>
                <div class="section5-box-item-title">抑郁预警</div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>就餐次数减少</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>就餐时长减少</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>体重异常</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>睡眠碎片化</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>睡眠障碍</span>
                </div>
              </div></el-col
            >
            <el-col :span="6" style="padding: 0 0.078125rem"
              ><div
                :style="{
                  marginTop: pxToRem(100),
                  padding: pxToRem([60, 40, 0, 40]),
                }"
                class="section5-box-item"
              >
                <div class="section5-box-item-img">
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section5-img3.png" alt="" />
                </div>
                <div class="section5-box-item-title">尿道感染预警</div>
                <div style="padding: 0 0.02604166rem">
                  <div class="section5-box-item-text">
                    <div class="section5-box-item-text-circle"></div>
                    <span>尿急、尿频</span>
                  </div>
                  <div class="section5-box-item-text">
                    <div class="section5-box-item-text-circle"></div>
                    <span>活动强度减少</span>
                  </div>
                  <div class="section5-box-item-text">
                    <div class="section5-box-item-text-circle"></div>
                    <span>心率变化</span>
                  </div>
                  <div class="section5-box-item-text">
                    <div class="section5-box-item-text-circle"></div>
                    <span>饮水减少</span>
                  </div>
                  <div class="section5-box-item-text">
                    <div class="section5-box-item-text-circle"></div>
                    <span>习惯性久坐</span>
                  </div>
                </div>
              </div></el-col
            >
            <el-col :span="6" style="padding: 0 0.078125rem"
              ><div class="section5-box-item">
                <div class="section5-box-item-img">
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section5-img4.png" alt="" />
                </div>
                <div class="section5-box-item-title">糖尿病预警</div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>多饮</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>多尿</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>多食</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>体重下降</span>
                </div>
                <div class="section5-box-item-text">
                  <div class="section5-box-item-text-circle"></div>
                  <span>视力下降</span>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="section6">
      <div class="section6-box">
        <el-row>
          <el-col :span="12"
            ><div class="section6-box-item">
              <div class="section6-box-item-img">
                <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section6-img1.png" alt="" /> -->
                <el-image
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section6-img1.png"
                  lazy
                ></el-image>
              </div></div
          ></el-col>
          <el-col :span="12"
            ><div
              :style="{ padding: pxToRem([0, 0, 0, 50]) }"
              class="section6-box-item"
            >
              <div class="section6-box-item-title">AI赋能,助力智慧健康</div>
              <div class="section6-box-item-tips">
                AI empowers and helps smart health
              </div>
              <div class="horizontal-line"></div>
              <div class="section6-box-item-tag">
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png" alt="" />
                <span>感知模式</span>
              </div>
              <div
                class="section6-box-item-text"
                style="padding-right: 0.26041666rem"
              >
                使用多模融合智能感知技术、深度学习神经网络算法，1CARE可以全天侯识别从起床到睡眠的所有模式；我们使用这些数据集来训练深度学习神经网络，这些神经网络能够捕捉到人类很难观察到的细微迹象；
              </div>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12"
            ><div
              class="section6-box-item"
              :style="{ padding: pxToRem([0, 0, 0, 50]) }"
            >
              <div
                style="justify-content: flex-end"
                class="section6-box-item-tag"
              >
                <span>趋势分析</span>
                <img
                  :style="{ marginLeft: pxToRem(10), marginRight: 0 }"
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                  alt=""
                />
              </div>
              <div class="section6-box-item-text">
                1CARE提供生活活动感知，将过去几天、几周或几个月的情况进行趋势分析，利用深度学习算化建立个体预测模型，识别其中的细微变化，这样我们就可以预测身体健康状况的细微变化，实现疾病的早防早治；
              </div>
              <div
                style="justify-content: flex-end"
                class="section6-box-item-tag"
              >
                <span>安心守护</span>
                <img
                  :style="{ marginLeft: pxToRem(10), marginRight: 0 }"
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                  alt=""
                />
              </div>
              <div class="section6-box-item-text">
                日常生活中每个人的生活方式都所有不同，例如，刷牙、吃饭、喝水等有数百种方式，深度学习功能，可以识别个体化的独特行为模式；例如，单一的生活方式，可能无法表明什么状态，但多状态综合性分析，可能正表明一个人的心理健康出现了风险；<br />
                健康报告中会突出显示令人担忧的行为下降趋势；<br />
                1CARE应用程序在云端运行，无需安装任何硬件，无论您身处哪里，都可以为您提供7*24小时的安全守护；
              </div>
              <!-- <div
                :style="{ marginTop: pxToRem(30) }"
                class="section6-box-item-text"
              >
                1CARE应用程序运行在云端，无需安装任何硬件，无论您身处哪里，都可以为您提供7*24小时的安全守护；而这一份安心，每天仅需1元钱！
              </div> -->
            </div></el-col
          >
          <el-col :span="12"
            ><div class="section6-box-item">
              <div class="section6-box-item-img">
                <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section6-img2.png" alt="" /> -->
                <el-image
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section6-img2.png"
                  lazy
                ></el-image>
              </div></div
          ></el-col>
        </el-row>
      </div>
    </div>
    <div class="section7-bg">
      <img class="section7-bg-img" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/wave-big.png" alt="" />
      <div class="section7">
        <div class="section4-header-box">
          <div class="section4-header-box-title">
            <span>助力疫情防控</span>
          </div>
          <div
            :style="{ marginTop: pxToRem(10) }"
            class="section4-header-box-tips"
          >
            Assist the epidemic prevention and control
          </div>
          <div
            :style="{ margin: '0 auto', marginTop: pxToRem(20) }"
            class="horizontal-line"
          ></div>
        </div>
        <div class="section7-box">
          <el-row>
            <el-col :span="12" style="padding: 0 0.1953125rem"
              ><div class="section7-box-item">
                <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section7-img1.png" alt="" /> -->
                <el-image
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section7-img1.png"
                  lazy
                ></el-image>
                <div class="section7-box-item-cover">
                  ——————<span>助益疫情筛查</span>
                </div>
              </div></el-col
            >
            <el-col :span="12" style="padding: 0 0.1953125rem"
              ><div class="section7-box-item">
                <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section7-img2.png" alt="" /> -->
                <el-image
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section7-img2.png"
                  lazy
                ></el-image>
                <div class="section7-box-item-cover">
                  ——————<span>智能化病情诊断</span>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <div :style="{ marginTop: pxToRem(75) }" class="section2-info-box">
          <div class="section2-info-box-text">
            <div :style="{ marginTop: pxToRem(20) }" class="section2-info-box">
              <div>
                <img
                  src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png"
                  :style="{
                    width: pxToRem(20),
                    height: pxToRem(20),
                    marginTop: pxToRem(8),
                  }"
                  alt=""
                />
              </div>
              <div class="section2-info-box-text">
                在新冠疫情肆虐时期，社会陷入了前所未有的孤立，1CARE作为护理者的一个重要智能化工具，是减轻照护压力的关键；在无法实现探视的情况下，让我们知道亲人一切安好，知道他们在做什么，这让我们安心工作；知道我们在时刻关注着他，只要轻按一下按钮就可以很容易地找到我们，这也让我们的亲人感到欣慰，自己并没有被家人遗忘；
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section8-bg">
      <div class="section8">
        <div class="section8-header-box">
          <div class="section8-header-box-title">
            医防融合，居家/社区智慧健康新概念
          </div>
          <div class="section8-header-box-tips">
            Integration of medical treatment and prevention, a new concept of
            smart health at home / community
          </div>
          <div class="horizontal-line"></div>
        </div>
        <div class="section8-box">
          <div class="section8-box-left">
            “我们创建1CARE健康，是出于我们个人迫切需要，希望1CARE就像我们照顾父母一样，可以精心地照顾他们的一切；现在，1CARE做的很好，即使不在身边，我们也可以安心的工作，1CARE令我们放心”。
          </div>
          <div class="section8-box-right">
            <img
              class="section8-box-right-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section8-img.png"
              alt=""
            />
            <!-- <div class="section8-box-right-play">
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section8-play.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section9">
      <div class="section9-title">
        1CARE为居家/养老机构护理人员提供专业，可操作的指导和建议
      </div>
      <div class="section9-swiper-box">
        <div v-for="(item, index) in swiperList" :key="index">
          <div
            :class="[
              index == swiperIndex
                ? 'section9-swiper-box-show'
                : 'section9-swiper-box-hide',
              'section9-swiper-box-base',
            ]"
          >
            <div
              class="section9-swiper-box-item"
              :style="[index == swiperIndex ? 'opacity:1' : 'opacity:0']"
            >
              <div class="section9-swiper-box-base-avatar">
                <img :src="item.img" alt="" />
              </div>
              <div class="section9-swiper-box-base-info-box">
                <div class="section9-swiper-box-base-info-box-title">
                  <span>{{ item.title }}</span>
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png" alt="" />
                </div>
                <div
                  :style="[index == swiperIndex ? 'opacity:1' : 'opacity:0']"
                  class="section9-swiper-box-base-info-box-text"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              index == swiperIndex
                ? 'section9-swiper-box-show'
                : 'section9-swiper-box-hide',
              'section9-swiper-box-base section9-swiper-box-base-shadow',
            ]"
          >
            <div class="section9-swiper-box-item">
              <div class="section9-swiper-box-base-avatar">
                <img :src="item.img" alt="" />
              </div>
              <div class="section9-swiper-box-base-info-box">
                <div class="section9-swiper-box-base-info-box-title">
                  <span>{{ item.title }}</span>
                  <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png" alt="" />
                </div>
                <div
                  :style="{
                    opacity: index == swiperIndex ? '1' : '0',
                    transition: 'all 0.3s',
                  }"
                  class="section9-swiper-box-base-info-box-text"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section9-box">
        <div class="section9-box-img">
          <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-img1.png" alt="" /> -->
          <el-image
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-img1.png"
            lazy
          ></el-image>
        </div>
        <div class="section9-box-info">
          <div class="section6-box-item-title" style="margin-top: 0">
            1CARE健康产品
          </div>
          <!-- <div class="section6-box-item-tips">1CARE health products kit</div> -->
          <div
            :style="{ marginLeft: pxToRem(5), marginBottom: pxToRem(60) }"
            class="horizontal-line"
          ></div>
          <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div>1CARE 手表</div>
          </div>
          <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div>选配表带</div>
          </div>
          <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div>充电器</div>
          </div>
          <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div style="display: flex; align-items: center">
              1CARE五个信标
              <span class="section9-box-info-tags-text1"
                >（易于安装在卧室、浴室、厨房、客厅、餐厅）</span
              >
            </div>
          </div>
          <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div>系统安装指南</div>
          </div>
          <!-- <div class="section9-box-info-tags">
            <div class="section9-box-info-tags-circle"></div>
            <div>
              <div>系统安装指南</div>
            </div>
          </div> -->
          <div class="section9-box-info-tips">有偿使用</div>
          <div class="section9-box-info-buy" @click="handleToAboutUs">
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-buy.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section9-info-box">
        <div class="section9-info-box-title">
          <span>1CARE健康产品特征</span>
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png" alt="" />
        </div>
        <div class="section9-info-box-tags">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-checked.png" alt="" />
          <span>双向音频触控通话按钮</span>
        </div>
        <div class="section9-info-box-tags">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-checked.png" alt="" />
          <span>支持Wi-Fi和SIM，NBIOT</span>
        </div>
        <div class="section9-info-box-tags">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-checked.png" alt="" />
          <span>iOS和安卓应用，应用内短信协调通信，亲情关爱圈</span>
        </div>
        <div class="section9-info-box-tags">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-checked.png" alt="" />
          <span
            >活动水平分数/主动警报/AI机器学习/日常生活活动模式识别/精准定位/SOS一键呼救
          </span>
        </div>
        <div class="section9-info-box-text">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-right.png" alt="" />
          <span
            >1CARE可穿戴设备非医疗设备，不具有医疗或专业护理意见，不是个人应急响应设备；1CARE可穿戴设备上的急救按钮，仅用于需要在非紧急时向1CARE亲情圈或家庭医生、护理人员等预先设定好的成员发送警报信息；1CARE可穿戴设备与“120”或其他紧急呼叫中心服务没有任何联系；</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {
      carouselHeight: "",
      sectionTwoList: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img1.png",
          width: 93,
          height: 74,
          text1: "是否吃饭",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img2.png",
          width: 89,
          height: 79,
          text1: "是否起床",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img3.png",
          width: 97,
          height: 80,
          text1: "睡眠不好",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img4.png",
          width: 69,
          height: 87,
          text1: "心情不好",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img5.png",
          width: 79,
          height: 87,
          text1: "是否跌倒",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img6.png",
          width: 78,
          height: 99,
          text1: "异常事件",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img7.png",
          width: 65,
          height: 91,
          text1: "是否会",
          text2: "发生糖尿病",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img8.png",
          width: 82,
          height: 85,
          text1: "血压",
          text2: "是否异常",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img9.png",
          width: 94,
          height: 94,
          text1: "是否有",
          text2: "眼底病变",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section2-img10.png",
          width: 70,
          height: 10,
          text1: "更多期待···",
          text2: "",
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
      ],
      sectionTwoObj: null,
      swiperList: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-avatar.png",
          title: "有效控制养老机构疫情传播",
          text: "“在新冠病毒疫情导致社会性疏远和社交距离保持时期，实时了解他们的感染风险，将使我们能够及时、准确地做出安全防护决策。感谢1CARE团队在这场大流行中，为老年康养行业做出如此迅速的反应。”",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section9-avatar1.png",
          title: "改善居家照护，1CARE值得信赖",
          text: "“1CARE能够帮助我们的护理人员提前预测老人可能出现的健康问题，从而增加我们为用户提供精准、专业的建议；同时，复杂的人工智能技术还可以使我们接触到更多的潜在客户，为居家养老服务提前做好储备。”",
        },
      ],
      swiperIndex: 0,
      section3Carousel: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-carousel-img1.png",
          width: 516,
          height: 766,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-carousel-img2.png",
          width: 482,
          height: 696,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-carousel-img3.png",
          width: 469,
          height: 591,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-carousel-img4.png",
          width: 514,
          height: 745,
        },
      ],
    };
  },
  mounted() {
    // this.$nextTick(() => {
    // let img = this.$refs.carouselImg[0];
    this.getCarouselHeight();
    window.onresize = () => {
      this.getCarouselHeight();
    };
    this.swiperInit();
  },
  methods: {
    getCarouselHeight() {
      let domWidth = document.body.clientWidth;
      this.carouselHeight = (domWidth * 1606) / 3840 + "px";
    },
    swiperInit() {
      setInterval(() => {
        this.swiperIndex = this.swiperIndex == 1 ? 0 : 1;
      }, 5000);
    },
    handleShowDetail(index) {
      this.sectionTwoObj = this.sectionTwoList[index];
    },
    handleHideDetail() {
      this.sectionTwoObj = null;
    },
    handleToAboutUs() {
      this.$router.push({ path: "/aboutUs", query: { target: "target" } });
    }
  },
};
</script>
<style lang="less" scoped>
.container {
  // min-width: 1200px;
  background: #eef1f5;
}
.carousel-box {
  width: 100%;
  .carousel-item {
    // width: 100%;
    // height: 100%;
    // background: url("../assets/home/carousel1.png") no-repeat;
    // background-size: 100%;
    .carousel-img {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1;
    }
    &-text-box {
      position: absolute;
      // background: rgba(255, 255, 255, 0.5);
      width: 1200px;
      box-sizing: border-box;
      // padding-left: 30px;
      // padding-right: 780px;
      // height: 300px;
      // left: 360px;
      top: 185px;
      left: 0;
      right: 0;
      margin: 0 auto;
      &-title {
        font-size: 60px;
        color: #1f3876;
        font-family: "UI-Bold";
        span {
          color: #90c446;
        }
      }
      &-tips {
        font-family: "CN-Light";
        color: #90c446;
        font-size: 31px;
      }
      &-info {
        width: 533px;
        font-family: "CN-Light";
        font-size: 21px;
        line-height: 40px;
        color: #213763;
      }
      &-btn {
        width: 185px;
        height: 45px;
        margin-top: 60px;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.8s;
      }
      &-btn:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
  /deep/ .el-carousel__indicators--outside {
    position: absolute;
    bottom: 80px;
    right: -185px;
  }
  /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
    width: 35px;
    height: 6px;
    border-radius: 20px;
    background: white;
    opacity: 1;
  }
  /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background: #1f3876;
  }
}
.horizontal-line {
  width: 72px;
  height: 4px;
  background: #90c446;
  margin: 20px 0;
  border-radius: 50px;
}
.horizontal-line-small {
  width: 72px;
  height: 2px;
  background: #90c446;
  margin: 20px 0;
}
.section1 {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-top: 92px;
  &-row {
    width: 100%;
    height: 300px;
    display: flex;
    &-box {
      width: 50%;
      height: 100%;
    }
    &-box1 {
      // padding-left: 55px;
      // padding-right: 55px;
      box-sizing: border-box;
      position: relative;
      .wave {
        width: 250px;
        height: 250px;
        position: absolute;
        top: -84px;
        left: -68px;
      }
      &-title {
        font-size: 64px;
        color: #1f3876;
        font-family: "UI-Bold";
      }
      &-tag {
        font-size: 27px;
        color: #1f3876;
        font-family: "UI_Light";
        // letter-spacing: 5px;
      }
      &-text {
        font-size: 21px;
        font-family: "CN-Light";
        color: #727171;
      }
    }

    &-img-box {
      // width: 380px;
      // height: 215px;
      width: 572px;
      height: 306px;
      // background: #ffffff;
      // border-radius: 19px;
      // box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      cursor: pointer;
      transition: all 0.8s;
      &-title-box {
        padding-top: 85px;
        margin-left: 82px;
      }
      &-title {
        color: #1f3876;
        font-size: 35px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        font-family: "CN_Medium";
        // width: 47px;
        img {
          width: 24px;
          height: 23px;
          margin-left: 16px;
        }
      }
      &-text {
        font-size: 21px;
        color: #939393;
        font-family: "CN_Light";
      }
    }
    &-img-box1 {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section1-img1.png") no-repeat;
      background-size: 100% 100%;
      background-position: right top;
      float: right;
    }
    &-img-box2 {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section1-img2.png") no-repeat;
      background-size: 100% 100%;
      width: 604px;
      height: 326px;
    }
    &-img-box3 {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section1-img3.png") no-repeat;
      background-size: 100% 100%;
      width: 574px;
      height: 335px;
      background-position: right top;
      float: right;
    }
    &-img-box:hover {
      transform: scale(1.02);
    }
    // &-circle {
    //   width: 120px;
    //   height: 120px;
    //   background: #90c446;
    //   border-radius: 50%;
    //   position: absolute;
    //   right: -20px;
    //   top: -45px;
    // }
  }
}
.section2 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 150px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #90c446;
      margin-top: 5px;
    }
  }
  &-box {
    width: 1200px;
    height: 535px;
    background: #ffffff;
    border-radius: 25px 25px 22px 25px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin-top: 30px;
    // padding: 84px 0;
    padding-bottom: 80px;
    box-sizing: border-box;
    &-row {
      display: flex;
      align-items: center;
      height: 50%;
      flex-wrap: wrap;
      &-item {
        width: 20%;
        height: 100%;
        color: #1f3876;
        text-align: center;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding-top: 84px;
        box-sizing: border-box;
        cursor: pointer;
        &-img {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text1 {
          font-family: "CN-Medium";
          font-size: 21px;
          margin-top: 25px;
          // margin-bottom: 0px;
        }
        &-text2 {
          font-family: "CN-Medium";
          font-size: 21px;
        }
      }
    }
  }
  &-info-box {
    margin-top: 70px;
    margin-left: 30px;
    display: flex;
    &-text {
      font-family: "CN-Light";
      font-size: 21px;
      width: 1050px;
      color: #213763;
      margin-left: 30px;
      line-height: 35px;
      text-align: justify;
    }
  }
}
.section3-bg {
  width: 100%;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section3-bg.png") no-repeat;
  background-position: 0px 250px;
  background-size: 100%;
}
.section3 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 200px;
  &-box {
    // margin-top:20px;
    width: 1200px;
    height: 980px;
    // background: #ffffff;
    // border-radius: 25px 25px 22px 25px;
    // box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin-top: 30px;
    // padding: 84px 0;
    box-sizing: border-box;
    display: flex;
    &-left {
      width: 500px;
      height: 100%;
      margin-right: 40px;
      background: white;
      border-radius: 25px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      box-sizing: border-box;
      // padding: 70px 0 0px 0;
      position: relative;
      cursor: pointer;
      &-carousel {
        width: 100%;
        // height: 766px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // img {
        //   float: right;
        // }
      }
      /deep/ .el-carousel__indicators--outside {
        position: absolute;
        bottom: 60px;
        left: 0;
        right: 0;
      }
      /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
        width: 30px;
        height: 10px;
        border-radius: 20px;
        background: #d8d9d9;
        opacity: 1;
      }
      /deep/
        .el-carousel__indicator--horizontal.is-active
        .el-carousel__button {
        background: #90c446;
        width: 60px;
      }
    }
    &-right {
      width: 660px;
      height: 100%;
      &-top {
        display: flex;
        &-left {
          width: 315px;
          height: 773px;
          background: white;
          border-radius: 18px;
          box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
          margin-right: 30px;
          box-sizing: border-box;
          padding: 19px 30px 0px 30px;
          cursor: pointer;
        }
        &-right {
          width: 315px;
          height: 773px;
          background: white;
          border-radius: 18px;
          box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
          box-sizing: border-box;
          padding: 19px 30px 0px 35px;
          cursor: pointer;
        }
        &-title {
          font-family: "CN-Medium";
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 28px;
          color: #1f3876;
        }
        &-text {
          font-family: "CN-Light";
          font-size: 19px;
          color: #727171;
          margin-top: 27px;
          text-align: justify;
        }
      }
      &-bottom {
        width: 100%;
        height: 184px;
        margin-top: 25px;
        background: white;
        box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
        border-radius: 18px;
        box-sizing: border-box;
        padding: 23px 67px 0 35px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &-left {
          flex: 1;
        }
        &-right {
          width: 135px;
          height: 135px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.section4 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 150px;
  &-header-box {
    text-align: center;
    &-title {
      font-size: 52px;
      color: #1f3876;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "UI-Bold";
      &-circle {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #90c446;
        margin: 0 20px;
      }
    }
    &-tips {
      color: #90c446;
      font-size: 31px;
      font-family: "CN-Light";
      margin-top: 5px;
    }
  }
  &-box {
    width: 100%;
    margin-top: 60px;
    &-item {
      width: 100%;
      height: 500px;
      background: white;
      border-radius: 20px;
      margin-top: 40px;
      position: relative;
      overflow: hidden;
      &-img {
        width: 100%;
        // border-radius:
      }
      &-block {
        width: 100%;
        height: 20px;
        background: #90c446;
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
      &-text-box {
        cursor: pointer;
        position: absolute;
        bottom: -180px;
        // left: -1px;
        // right: -1
        width: 100%;
        background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section4-img-bg.png") no-repeat;
        background-size: 100% 100%;
        height: 370px;
        padding: 35px 55px 30px 55px;
        box-sizing: border-box;
        transition: all 0.8s;
        &-title {
          font-family: "CN-Medium";
          font-size: 31px;
          color: #1f3876;
          display: flex;
          align-items: center;
          &-img {
            width: 24px;
            height: 23px;
            margin-left: 18px;
            background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-up.png") no-repeat;
            background-size: 100% 100%;
            transition: all 0.8s;
          }
        }
        &-tips {
          font-family: "UI-Light";
          color: #1f3876;
          font-size: 25px;
          margin-top: 20px;
        }
        &-info {
          color: #727171;
          margin-top: 30px;
          font-size: 21px;
        }
      }
      &-text-box:hover {
        bottom: 0;
      }
      &-text-box:hover &-text-box-title-img {
        background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
.section5-bg {
  padding-top: 100px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section5-bg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 1150px;
}
.section5 {
  width: 1200px;
  margin: 0 auto;
  padding-top: 1px;
  &-header-box {
    margin-top: 225px;
    margin-left: 30px;
    color: white;
    &-title {
      font-family: "UI-Bold";
      font-size: 52px;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
    }
  }
  &-box {
    margin-top: 50px;
    &-item:hover {
      transform: scale(1.02);
    }
    &-item {
      cursor: pointer;
      width: 100%;
      height: 550px;
      background: #ffffff;
      border-radius: 26px 26px 26px 26px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.25);
      box-sizing: border-box;
      padding: 60px 50px 0 50px;
      transition: all 0.8s;
      &-img {
        display: flex;
        justify-content: center;
        img {
          width: 150px;
          height: 150px;
        }
      }
      &-title {
        font-size: 30px;
        color: #1f3876;
        margin: 35px 0 26px 0;
        font-family: "CN-Medium";
      }
      &-text {
        display: flex;
        align-items: center;
        color: #939393;
        margin-bottom: 5px;
        font-size: 21px;
        font-family: "CN-Light";
        &-circle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #939393;
          margin-right: 10px;
        }
      }
    }
  }
}
.section6 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 150px;
  &-box {
    &-item {
      width: 100%;
      height: 500px;
      box-sizing: border-box;
      &-img {
        cursor: pointer;
        transition: all 0.8s;
        padding: 0 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-img:hover {
        transform: scale(1.02);
      }
      &-title {
        color: #1f3876;
        font-size: 52px;
        font-family: "UI-Bold";
        margin-top: 20px;
      }
      &-tips {
        color: #90c446;
        font-size: 31px;
        font-family: "CN-Light";
        margin-top: 5px;
      }
      &-tag {
        display: flex;
        align-items: center;
        font-size: 32px;
        color: #1f3876;
        margin-top: 60px;
        margin-bottom: 10px;
        font-family: "CN-Medium";
        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
      &-text {
        font-family: "CN-Light";
        font-size: 18px;
        color: #727171;
        text-align: justify;
      }
    }
  }
}
.section7-bg {
  position: relative;
  &-img {
    width: 100%;
    position: absolute;
    width: 100%;
    top: -210px;
  }
}
.section7 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 140px;
  &-box {
    margin-top: 50px;
    &-item {
      width: 562px;
      height: 471px;
      border-radius: 35px 35px 35px 35px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      position: relative;
      overflow: hidden;
      color: white;
      cursor: pointer;
      transition: all 0.8s;
      img {
        width: 100%;
        height: 100%;
      }
      &-cover {
        width: 100%;
        height: 46px;
        line-height: 46px;
        text-align: right;
        box-sizing: border-box;
        padding-right: 70px;
        background: rgba(144, 196, 70, 0.6);
        position: absolute;
        bottom: 0;
        left: 0;
        span {
          margin-left: 20px;
          font-family: "CN-Regular";
          font-size: 19px;
        }
      }
    }
    &-item:hover {
      transform: scale(1.02);
    }
  }
}
.section8-bg {
  margin-top: 150px;
  width: 100%;
  height: 900px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/section8-bg.png") no-repeat;
  background-size: 100% 100%;
}
.section8 {
  padding-top: 200px;
  width: 1200px;
  margin: 0 auto;
  padding-left: 30px;
  &-header-box {
    color: white;
    &-title {
      font-size: 52px;
      font-family: "UI-Bold";
      margin-left: -25px;
    }
    &-tips {
      font-size: 31px;
      padding: 30px 0 15px 0;
      font-family: "CN-Light";
    }
  }
  &-box {
    margin-top: 30px;
    display: flex;
    &-left {
      margin-right: 90px;
      padding-top: 200px;
      width: 367px;
      color: white;
      text-align: justify;
      font-size: 20px;
      font-family: "CN-Light";
    }
    &-right {
      width: 704px;
      height: 380px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.25);
      border-radius: 30px;
      position: relative;
      &-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: 0.8s;
      }
      &-img:hover {
        transform: scale(1.02);
      }
      &-play {
        position: absolute;
        width: 128px;
        height: 82px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto auto;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.section9 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  padding-bottom: 190px;
  &-title {
    font-size: 36px;
    color: #1f3876;
    text-align: center;
    font-family: "UI-Light";
  }
  &-swiper-box {
    margin-top: 50px;
    position: relative;
    width: 100%;
    height: 260px;
    // clear: both;
    &-item {
      width: 100%;
      height: 100%;
      display: flex;
      transition: all 2s;
    }
    &-base {
      width: 1151px;
      height: 214px;
      background: #ffffff;
      border-radius: 20px;
      transition: all 0.8s;
      box-sizing: border-box;
      padding: 35px 40px;
      &-shadow {
        box-shadow: 16.35px 14.72px 35px 22px rgba(0, 15, 33, 0.1);
      }
      &-avatar {
        width: 137px;
        height: 137px;
        border-radius: 50%;
        border: 4px solid #90c446;
        box-sizing: border-box;
        overflow: hidden;
        margin-right: 95px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-info-box {
        flex: 1;
        &-title {
          display: flex;
          align-items: center;
          color: #1f3876;
          font-size: 32px;
          font-family: "CN-Medium";
          margin-bottom: 10px;
          img {
            width: 23px;
            height: 23px;
            margin-left: 27px;
          }
        }
        &-text {
          font-size: 21px;
          font-family: "CN-Light";
          color: #727171;
          line-height: 36px;
        }
      }
    }
    &-show {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    &-hide {
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: 1;
    }
  }
  &-box {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    &-img {
      width: 548px;
      height: 592px;
      cursor: pointer;
      transition: all 0.8s;
      margin-right: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-img:hover {
      transform: scale(1.02);
    }
    &-info {
      // padding-top: 30px;
      padding-right: 0px;
      &-tags {
        color: #1f3876;
        font-size: 26px;
        font-family: "CN-Regular";
        display: flex;
        align-items: center;
        margin-top: 30px;
        transition: all 0.5s;
        cursor: pointer;
        // line-height: 26px;
        &-circle {
          width: 13px;
          height: 13px;
          background: #90c446;
          border-radius: 50%;
          margin-right: 24px;
        }
        &-text1 {
          font-size: 18px;
          font-family: "CN-Light";
          color: #939393;
        }
      }
      &-tags:hover {
        // transform-origin: left top;
        transform: scale(1.02);
      }
      &-tips {
        font-size: 20px;
        font-family: "CN-Light";
        color: #90c446;
        padding-left: 37px;
        margin-top: 17px;
      }
      &-buy {
        cursor: pointer;
        width: 191px;
        height: 57px;
        margin-top: 48px;
        transition: all 0.8s;
        border-radius: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-buy:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &-info-box {
    padding-left: 30px;
    margin-top: 130px;
    &-title {
      display: flex;
      align-items: center;
      font-size: 60px;
      font-family: "UI-Bold";
      color: #1f3876;
      margin-bottom: 60px;
      img {
        width: 30px;
        height: 30px;
        margin-left: 40px;
      }
    }
    &-tags {
      display: flex;
      font-size: 26px;
      font-family: "CN-Normal";
      color: #1f3876;
      margin-bottom: 30px;
      img {
        width: 29px;
        height: 21px;
        margin-right: 30px;
        margin-top: 10px;
      }
    }
    &-text {
      margin-top: 80px;
      display: flex;
      font-size: 21px;
      font-family: "CN-Light";
      color: #939393;
      line-height: 35px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 30px;
        margin-top: 6px;
      }
    }
  }
}
.sectionItemBig {
  display: flex;
  align-items: center;
  padding: 40px 139px 0 98px;
  .itemLeft {
    margin-right: 131px;
    text-align: center;
    font-family: "CN-Blod";
    font-size: 28px;
    margin-top: 30px;
    font-weight: bold;
    color: #1f3876;
    cursor: pointer;
    img {
      width: 159px;
    }
  }
  .itemRight {
    p {
      font-family: "CN-Light";
      font-size: 19px;
      line-height: 32px;
      color: #727171;
      margin-top: 40px;
      position: relative;
      // width: 644px;
    }
    p::before {
      width: 11px;
      height: 11px;
      content: "";
      background: #727171;
      border-radius: 50%;
      position: absolute;
      left: -23px;
      top: 12px;
    }
  }
}
</style>
